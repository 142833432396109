import React, { useState, useEffect } from "react";
import PlayPauseButton from '../../elements/songElements/playPauseButton';  
import {connect} from 'react-redux';
import DropboxButton from '../../elements/songElements/dropboxButton';
import SingleDownloadButton from '../../elements/songElements/singleDownloadButton';
import CrateButton from '../../elements/songElements/crateButton';

const  SongVersion = ({ version, customerDownloads, song, activeBtn, themeType, isCuratedSpotlight}) => {
    
    const downloadLoop = [1,2,3];

    return (
        <div className="block">
            <div className="play-track">
                <PlayPauseButton parentComponent="songVersion" song={song} version={version} />
                <div className="track-name">
                    <span className="text-2">{version.version_label}</span>
                </div>
            </div>
            <div className="play-track-content">
                
                <div className="divider">
                {
                    // version.download.map((downloadVersion, index) => {
                    //         return <span key={`downloadVersion${version.song_version_id}${index}`}>
                    //                 <svg  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //                 <path d="M10.0003 18.9582C5.05866 18.9582 1.04199 14.9415 1.04199 9.99984C1.04199 5.05817 5.05866 1.0415 10.0003 1.0415C14.942 1.0415 18.9587 5.05817 18.9587 9.99984C18.9587 14.9415 14.942 18.9582 10.0003 18.9582ZM10.0003 2.2915C5.75033 2.2915 2.29199 5.74984 2.29199 9.99984C2.29199 14.2498 5.75033 17.7082 10.0003 17.7082C14.2503 17.7082 17.7087 14.2498 17.7087 9.99984C17.7087 5.74984 14.2503 2.2915 10.0003 2.2915Z" fill={ customerDownloads[version.song_version_id] > index ?  themeType == 'dark-theme' ? '#F4FD84' : "#EB4E27"   : themeType == 'dark-theme' ? '#FFFFFF' : "#282A2D"} />
                    //                 <path d="M8.81621 12.9832C8.64954 12.9832 8.49121 12.9165 8.37454 12.7998L6.01621 10.4415C5.77454 10.1998 5.77454 9.79984 6.01621 9.55817C6.25788 9.3165 6.65788 9.3165 6.89954 9.55817L8.81621 11.4748L13.0995 7.1915C13.3412 6.94984 13.7412 6.94984 13.9829 7.1915C14.2245 7.43317 14.2245 7.83317 13.9829 8.07484L9.25788 12.7998C9.14121 12.9165 8.98288 12.9832 8.81621 12.9832Z" fill={ customerDownloads[version.song_version_id] > index ? themeType == 'dark-theme' ? '#F4FD84' : "#EB4E27"  : themeType == 'dark-theme' ? '#FFFFFF' : "#282A2D"} />
                    //             </svg>
                    //         a</span>
                        
                    // })
                    downloadLoop.map((downloadVersion, index) => {
                        return <span key={`downloadVersion${version.song_version_id}${index}`}>
                            <svg  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0003 18.9582C5.05866 18.9582 1.04199 14.9415 1.04199 9.99984C1.04199 5.05817 5.05866 1.0415 10.0003 1.0415C14.942 1.0415 18.9587 5.05817 18.9587 9.99984C18.9587 14.9415 14.942 18.9582 10.0003 18.9582ZM10.0003 2.2915C5.75033 2.2915 2.29199 5.74984 2.29199 9.99984C2.29199 14.2498 5.75033 17.7082 10.0003 17.7082C14.2503 17.7082 17.7087 14.2498 17.7087 9.99984C17.7087 5.74984 14.2503 2.2915 10.0003 2.2915Z" fill={ customerDownloads[version.song_version_id] > index ?  themeType == 'dark-theme' ? '#ff07b3' : "#ff07b3"   : themeType == 'dark-theme' ? '#FFF' : "#282A2D"} />
                                <path d="M8.81621 12.9832C8.64954 12.9832 8.49121 12.9165 8.37454 12.7998L6.01621 10.4415C5.77454 10.1998 5.77454 9.79984 6.01621 9.55817C6.25788 9.3165 6.65788 9.3165 6.89954 9.55817L8.81621 11.4748L13.0995 7.1915C13.3412 6.94984 13.7412 6.94984 13.9829 7.1915C14.2245 7.43317 14.2245 7.83317 13.9829 8.07484L9.25788 12.7998C9.14121 12.9165 8.98288 12.9832 8.81621 12.9832Z" fill={ customerDownloads[version.song_version_id] > index ? themeType == 'dark-theme' ? '#ff07b3' : "#ff07b3"  : themeType == 'dark-theme' ? '#FFF' : "#282A2D"} />
                            </svg>
                        </span>
                    })
                }
                </div>
                <div className="divider version-download">
                    {version.total_version_download ? <span className="download-report-count small-count">{version.total_version_download}</span> : ''}
                    { activeBtn == 'download' ? <SingleDownloadButton songId={song.entity_id} version={version} songSectionId={song.song_section_id} isCkExclusive={song.isCkExclusive} isCuratedSpotlight={isCuratedSpotlight} /> : ''}
                    { activeBtn == 'dropbox' ? <DropboxButton songId={song.entity_id} version={version} songSectionId={song.song_section_id} isCkExclusive={song.isCkExclusive} isCuratedSpotlight={isCuratedSpotlight} /> : ''}
                </div>
                <div>
                    <CrateButton songId={song.entity_id} version={version} />
                </div>
            </div>
    
        </div>
                        
    );
  
}


function mapStateToProps(state) {
    return {
        customerDownloads: state.download.customerDownloads,
        activeBtn: state.activeDownloadBtn.activeDownloadBtn,
        themeType: state.theme.theme,
    };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(SongVersion);
