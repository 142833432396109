
import React, {useEffect, useState} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import SinglePageLayout from "../../layouts/singlePageLayout/singlePageLayout";
import AccountNav from '../../elements/accountElements/accountNav'; 
import { checkCustomer } from '../../components/utlity/checkCustomer';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link, useLocation, useNavigate } from "react-router-dom";
import {connect} from 'react-redux';
import {isDesktop, isMobile, isTablet, browserName, browserVersion, osName, osVersion, isMobileOnly} from 'react-device-detect';

import * as subscriptionAction from '../../store/subscription/actions'
import * as billingAction from '../../store/billing/actions';
import * as customerAction from '../../store/customer/actions';
import * as couponAction from '../../store/coupon/actions';
import logo from './../../assets/images/cklogo.png';
import membershipLogo from './../../assets/images/members-deal-50.png';
import CalanderSgv from './../../assets/images/calendar-group.svg';

const Funnel = ({ displayLoading, hideLoading, getCustomerAgreement, agreement, getServices, plans, getUserProfile, getPlanDetails, cancelCustomerFeedback, customerProfile, validateCoupon, loggingIn}) => {

    const MySwal = withReactContent(Swal);
    const [ processing, setProcessing ] = useState(false);
    const [ isStaff, setIsStaff ] = useState(false);
    const [ userDeviceInfo, setUserDeviceInfo ] =  useState({
        device_type: isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet',
        browser_name: browserName,
        browser_version: browserVersion,
        os_name: osName,
        os_version: osVersion,
        ip_address: '',
        token: localStorage.user,
        device_id: localStorage.getItem('deviceId')
    });
    const [  ] = useState();
    const [ couponApplied, setCouponApplied ] = useState(false);
    const [processCoupon, setProcessCoupon ] = useState(false);
    const [ coupon, setCoupon ] = useState();
    const [subscription, setSubscription] = useState({
                            "trial": {
                                "price": "180.00",
                                "trial_length": 1,
                                "trial_period": "Year",
                                "trial_interval": 1
                            },
                            "price": "$360.00",
                            "id": 30,
                            "amount": "360.00",
                            "service_title": "Yearly 50% OFF",
                            "payment_period": "Year",
                            "payment_interval": 1,
                            "description": "$15 every month for 1st year",
                            "plan_name": "Yearly Plan Subscription",
                            "plan_detail": " $180.00  first  1 Year thereafter  $360.00 every  Year"
                        }
                    );
    const navigate = useNavigate();

    useEffect(() => { 
		if(loggingIn !== undefined && loggingIn === false){
            window.location.href = '/logout';
		} 
    },[])

    
    
    useEffect(() => { 
		// load Page Data
        
		loadPageData();
    },[]);
    // console.log('plans', plans)
    useEffect(() => {
        if(customerProfile){
        if(checkCustomer(customerProfile)){
                window.location.href = '/';
            }
            if(checkPremiumMember(customerProfile)){
                window.location.href = '/';
            }
        }
        // if(customerProfile && customerProfile.group_id == 3){
        //     var d1 = new Date();
        //     var d2 = new Date(customerProfile.staff_membership_expire_date);
        //     if(d2.getTime() > d1.getTime()){
        //         // setIsStaff(true); 
        //         window.location.href = '/';
        //     }
        // } 
    },[customerProfile]);

    useEffect(() => {
        if(plans && plans[0]){
            // setSubscription(plans[0])
            // applyCoupon(plans[0]);
        }
    },[plans]);


    const applyCoupon = async(plan) => {
        // console.log('plan', plan)
        // if(couponCode){
            setProcessCoupon(true);
            // call Validate coupon code
            await validateCoupon({coupon_code: 'CYBERMONDAY2024', subscription_id: plan.id}).then((res)=>{
                if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.status == 'true'){
                    setCoupon(res.payload.data);
                    setCouponApplied(true);
                    getPlanDetails({coupon: res.payload.data,  subscription: plan}).then(res => {
                        
                    })
                   
                } else {
                    setCoupon('');
                    setCouponApplied(false);
                    // setCouponValidationError(res.payload.message.errors[0]);

                }
            });
            setProcessCoupon(false);
    }

    const sendToPaymentMethodPage = () => {
        navigate('/subscription/payment-method/'+subscription.id);
    }


    const loadPageData = async() => {
        setProcessing(true);
        displayLoading();
        await getUserProfile(userDeviceInfo)
        await getCustomerAgreement();
        await getServices({subscription_type_id: 1});
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    }

    const sendCustomerCancelFeedback = (message) => {
        
        cancelCustomerFeedback({message: message}).then(res  => {
			Swal.fire({
				title: "Thank you for your valuable feedback!", 
				text: "Hope to see you soon",
				type: "success", 
				closeOnConfirm: true
			}, function(){
                
            });
        });
    }
    // console.log('agreement', agreement)
    return (
        <SinglePageLayout>
            <div className="funnel-container">
                <div className="top-logo">
                    <img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in" style={{maxWidth: '100px' }} />
                </div>
                <h1 className="funnel-heading1">BEST DEAL EVER!</h1>
                <h2 className="funnel-heading2">UNBEATABLE OFFER YOU CAN'T MISS!</h2>
                <h2 className="funnel-heading2">50% OFF FOR 1 YEAR MEMBERSHIP</h2> 
                {/* <h2 className="funnel-heading2">$15.00 RECURRING</h2> */}
                
                <div className="middel-logo">
                    <img src={membershipLogo} alt="Membership" title="Membership" style={{maxWidth: '70%' }} />
                </div>
                <div className="account-inner-block">
                    <div className="subscription-sec">
                        <div className="subscription-block">
                            <div className="subscription-box">
                                <div className="inner-block">
                                    <span className="icon">
                                        <img src={CalanderSgv} title=""/>
                                        </span>
                                    <h2>BEST DEAL EVER!</h2>
                                    <h1>$180<span>a year</span></h1>
                                    <div className="feature-list">
                                        <ul className="feateureList">
                                            <li><i className="lnir lnir-checkmark-circle"></i> Download Rescue</li>
                                            <li><i className="lnir lnir-checkmark-circle"></i> Entire curated spotlight</li>
                                            <li><i className="lnir lnir-checkmark-circle"></i> DropBox Saver </li>
                                            <li><i className="lnir lnir-checkmark-circle"></i> One Click Download </li>
                                            <li><i className="lnir lnir-checkmark-circle"></i> Create Personal Playlist </li>
                                            <li><i className="lnir lnir-checkmark-circle"></i> One Click Download Access </li>
                                        </ul>
                                    </div>
                                </div>
                                <span className="secondary-btn disabled-btn" onClick={()=>sendToPaymentMethodPage()}>Unlock Offer</span>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <br /> <br /><br />
            </div>
        </SinglePageLayout>
    );
}
function mapStateToProps(state) {
  return {
	agreement: state.subscription.agreement,
    plans: state.subscription.plans,
    customerProfile: state.customer.customerProfile,
    loggingIn: state.authentication.loggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  	return {
      	getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        getServices: (post) => dispatch(subscriptionAction.getServices(post)),
        getUserProfile: (token) => dispatch(customerAction.getCustomerProfile(token)),
		downloadBillingEntry: (post) => dispatch(billingAction.downloadBillingEntry(post)),
		cancelSubscription: () => dispatch(subscriptionAction.cancelSubscription()),
        cancelCustomerFeedback: (post) => dispatch(subscriptionAction.cancelCustomerFeedback(post)),
        reactivateSubscription: () => dispatch(subscriptionAction.reactivateSubscription()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        validateCoupon: (post) => dispatch(couponAction.validateCoupon(post)),
        getPlanDetails: (post) => dispatch(subscriptionAction.getPlanDetails(post)),
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Funnel);