import * as types from './type';

const initialState = {
  coupon: ''
};



export default function couponReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.VALIDATE_COUPON: {
        // console.log('reducer coupon', action.payload.data)
        if (action.payload && action.payload.data) {
            return {
                ...state,
                coupon: action.payload.data,
            }
        } else {
            return {
                ...state
            }
        }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}