import React, {useState, useEffect} from "react";

import SinglePageLayout from "../../layouts/singlePageLayout/singlePageLayout";
import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useFormInputValidation } from "react-form-input-validation";
import { Link } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

// import logo from './../../assets/images/Logo.svg';
import logo from './../../assets/images/cklogo.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as actions from '../../store/authentication/actions';
import * as customerActions from '../../store/customer/actions';

const SignIn = ({ login, loggingIn, forgetPassword  }) => {
	const MySwal = withReactContent(Swal);
	const navigate = useNavigate();
	const [fields, errors, form] = useFormInputValidation({
		email: "",
		password: ""
	  }, {
		email: "required|email",
		password: "required"
	});
	
  const [showPass, setShowPass] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const [upgradedUser, setUpgradedUser] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [forgetErr, setForgetErr] = useState();
	const [successMessage, setSuccessMessage] = useState(null);
	const [ responseError, setResponseError ] = useState();
	const [ showDJSubmit, setShowDJSubmit ] = useState(false);
	const [ isSubmit, setIsSubmit ] = useState(false);

	useEffect(() => {
		
		if(loggingIn){
			// navigate('/')
			window.location.href = '/';
		}
		document.body.classList.add('hideplayer');
	},[]);

	const showPassword = () => {
		if(!showPass){
		  setPasswordType('text');
		} else {
		  setPasswordType('password');
		}
		setShowPass(!showPass);
	}

	
	const onSubmit = async (event) => {
		
		const isValid = await form.validate(event);

		if (isValid) {
			setIsSubmit(true);
			setResponseError('');
			setSuccessMessage('');
			setProcessing(true);
			await login(fields).then(res => { 
		  
				if(res.payload.response && res.payload.response.status === 401){
				  if(res.payload.response.data.message === 'You have not submitted DJ Application.'){
						setProcessing(false);
						setResponseError(res.payload.response.data.message);
						setShowDJSubmit(true);
						navigate(`/dj-app/${fields.email}`);
				  } else if( res.payload.response.data.message === "We've enhanced your ClubKillers experience. Please reset your password to access these new features."){
						setUpgradedUser(true);
						setResponseError(res.payload.response.data.message);
					} else { 
						setShowDJSubmit(false);
						if(res.payload.response.data.message == 'Your account is under process. You will be notified when processed.'){
							MySwal.fire({
								title: 'Account Review in Progress',
								text: "Your account is currently under review. You will receive a notification once the processing is complete. Your Continued Patience is Appreciated.",
								type: "success",
								allowOutsideClick: false,
							})
						} else { 
							setResponseError(res.payload.response.data.message);
						}
						
				  }
				} else if(res.payload.data) {
				 
					// setLoginErr('');
					localStorage.setItem('user', res.payload.data.token);
					window.location.href = '/';
					// setShow(false);
					// getUserData(res.payload.data.token);
				}
				setProcessing(false)
			}).catch(err=>{ 
				  setProcessing(false)
			});
		}
	}

	const forgetPasswordRequest = async (post) => {
		setProcessing(true);
		await forgetPassword(post).then(res => { 
			setResponseError('');
			if(res.payload.response && res.payload.response.status === 401){
				setForgetErr(res.payload.response.data.message.errors);
				setSuccessMessage('');
			} else if(res.payload.data) {
				setForgetErr('');
				setSuccessMessage('Please check your email to reset your password');
				setUpgradedUser(false);
			}
			setProcessing(false)
		}).catch(err=>{ 
		// console.log('hello', err)
			setProcessing(false)
		});
	}

	
  return (
    <SinglePageLayout>
		<SimpleBar style={{ height: '100vh' }}>
        <div className="sign-in">
			<div className="sign-in-left">
				<div className="top">
					<Link to={`/sign-in`} className="logo"><img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in" style={{maxWidth: '100px' }} /></Link>
							{/* <a href="#" className="close">
								<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
								</svg>
							</a> */}
				</div>
				<div className="form-sec">
					<h4>Welcome to Club Killers!</h4>
					<h1>Sign In</h1>
					
					<div>
						{
							responseError ? 
								<div className="alert alert-danger">{responseError} {showDJSubmit ? <span>Click <Link to={`/dj-app/${fields.email}`} className="text-2">here</Link> to submit DJ APP</span> : '' }</div> 
							: 
							''
						}
						{
							successMessage && successMessage !== '' ?
								<div className="alert alert-success">{successMessage}</div>
							: ''
						}
						{
							forgetErr && forgetErr !== '' ?
								<div className="alert alert-danger">{forgetErr}</div>
							: ''
						}
						{
							upgradedUser === false ? 
						<form className="myForm"
								noValidate
								onSubmit={onSubmit}>
							
							<div className="form-group">
								<label className="text-3">Email</label>
								<input className={ errors.email ? "text-3 form-control error-input" : "text-3 form-control" }
										type="text" 
										name="email"
										onBlur={form.handleBlurEvent}
          								onChange={form.handleChangeEvent}
										placeholder="Enter your email"/>
								<span className="error">{ errors.email ? fields.email != '' ? 'Please enter valid Email Address': 'The email field is required' : ""}</span>
							</div>
							<div className="form-group">
								<label className="text-3">Password</label>
								<div className="input-pass">
									<input className={ errors.password ? "text-3 form-control error-input" : "text-3 form-control" }
											name="password"
											type={passwordType}
											onBlur={form.handleBlurEvent}
														onChange={form.handleChangeEvent}
											onClick={() => setResponseError('')}
											placeholder="Password"/>
									{ passwordType === 'password' ?
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={showPassword}>
										<path d="M2.80658 7.06992C6.03344 1.64336 13.9666 1.64336 17.1934 7.06992C18.2689 8.87846 18.2689 11.1215 17.1934 12.9301C13.9666 18.3566 6.03344 18.3566 2.80658 12.9301C1.73114 11.1215 1.73114 8.87846 2.80658 7.06992Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.1615 10.0531C13.1615 11.7991 11.7455 13.2141 9.9995 13.2141C8.2535 13.2141 6.8385 11.7991 6.8385 10.0531C6.8385 8.30611 8.2535 6.89111 9.9995 6.89111C11.7455 6.89111 13.1615 8.30611 13.1615 10.0531Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M17.5 3L2 17" stroke="#D7D7D7" strokeLinecap="round"/>
									</svg>
									:
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={showPassword}>
										<path d="M2.80658 7.06992C6.03344 1.64336 13.9666 1.64336 17.1934 7.06992C18.2689 8.87846 18.2689 11.1215 17.1934 12.9301C13.9666 18.3566 6.03344 18.3566 2.80658 12.9301C1.73114 11.1215 1.73114 8.87846 2.80658 7.06992Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.1615 10.0531C13.1615 11.7991 11.7455 13.2141 9.9995 13.2141C8.2535 13.2141 6.8385 11.7991 6.8385 10.0531C6.8385 8.30611 8.2535 6.89111 9.9995 6.89111C11.7455 6.89111 13.1615 8.30611 13.1615 10.0531Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
									}
								</div>
								<span className="error text-3">{ errors.password ? errors.password : ""}</span>
							</div>
							<div className="custominput">
								<label className="custom-checkbox">
									<span className="text-2">Remember Password</span>
									<input type="checkbox" name="remember"   />
									<span className="checkmark"></span>
								</label>
							</div>
							<div className="form-group">
								{ processing ? <button className="primary-btn" disabled={processing}><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Sign in</button> }
							</div>
						</form>
						:
							processing ? 
								<button className="primary-btn" disabled={processing}><Spinner animation="border" variant="dark" size="sm" /></button> 
							: 
								successMessage ? 
									<Link to={`/`} className="text-2 fgp">Logins</Link>
								:
									<Button className="primary-btn" onClick={() => forgetPasswordRequest({email: fields.email})}>Reset Password</Button>
						}
						{
						upgradedUser === false ? 
						<div>
							<div className="form-group">
								<Button className="secondary-btn" onClick={() => navigate(`/sign-up`)}>Create An Account</Button>
							</div>
							<Link to={`/recover-password`} className="text-2 fgp">Forgot Password?</Link>
						</div>
						: ''
					}
					</div>
					
				</div>
			</div>
			<div className="sign-in-right">
				<div className="inner-bg">
					<div className="content">
						<div className="custom-font">ELEVATING DJ CULTURE ONE BEAT AT A TIME.</div>
						<h2>The world’s most influential DJ community & lifestyle brand.</h2>
					</div>
				</div>
			</div>
		</div>
		</SimpleBar>
    </SinglePageLayout>
  );
}

function mapStateToProps(state) {
  return {
		loggingIn: state.authentication.loggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
	login: (post) => dispatch(actions.signIn(post)),
	forgetPassword: (post) => dispatch(customerActions.forgetPassword(post)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);