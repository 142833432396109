import React, {useState, useEffect, createRef} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form'
import { PatternFormat } from 'react-number-format';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as commonActions from '../../store/common/actions';
import * as customerAction from '../../store/customer/actions';
import * as subscriptionAction from '../../store/subscription/actions'
import { logout } from "../../store/authentication/actions";






const UpdatePayPalPaymentCard = ({customerProfile, getCountryList, countryList, agreement, plans, getUserProfile, user, displayLoading, hideLoading, updateCard, getCustomerAgreement}) => {
    const MySwal = withReactContent(Swal);
    const [ isStaff, setIsStaff ] = useState(false)
    const [formData, setFormData] = useState({
        card_number: '',
        card_type: '',
        expire_date: '',
        ccv: '', 
        payment_method: '', 
        terms_condition: '',
        street:'',
        city:'',
        state: '',
        zip_code:'',
        country:''
    });
    const visaTypeOptions = [
        {label: "Visa", value: "Visa"},
        {label: "MasterCard", value: "MasterCard"},
        {label: "Discover", value: "Discover"},
        {label: "Amex", value:"Amex"}
    ];
    const [formDataEnter, setFormDataEnter] = useState(false);
    const [isCountrySelected, setIsCountrySelected] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ formSubmited, setForrmSubmited ] = useState(false);
    const [ responseError, SetResponseError ] = useState();
    const [ responseSuccess, SetResponseSuccess ] = useState();
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [region, setRegion] = useState();
    const [inputValue, setInputValue] = useState('');

    useEffect(() => { 
        getCountryList();
        getCustomerAgreement();
    },[]);

    useEffect(() => {
        if(customerProfile && customerProfile.group_id == 3){
            
            var d1 = new Date();
            var d2 = new Date(customerProfile.staff_membership_expire_date);
            if(d2.getTime() > d1.getTime()){
                setIsStaff(true);
            }
        }
    },[customerProfile]);

    useEffect(() => { 
        if(customerProfile && formDataEnter === false){
            setFormData({
                firstname: customerProfile && customerProfile.firstname ? customerProfile.firstname : '',
                lastname: customerProfile && customerProfile.lastname ? customerProfile.lastname : '',
                street: customerProfile && customerProfile.street ? customerProfile.street : '',
                country_id: customerProfile && customerProfile.country_id ? customerProfile.country_id : '',
                region_id: customerProfile && customerProfile.region_id ? customerProfile.region_id : '',
                city: customerProfile && customerProfile.city ? customerProfile.city : '',
                postcode: customerProfile && customerProfile.postcode ? customerProfile.postcode : '',
                email: customerProfile && customerProfile.email ? customerProfile.email : '',
                dj_name: customerProfile && customerProfile.dj_name ? customerProfile.dj_name : '',
            });
            setFormDataEnter(true);
            setInputValue(customerProfile && customerProfile.city ? customerProfile.city : '')
        }
        if(isCountrySelected == false && customerProfile &&  countryList && countryList.length > 0){
            const selectedCountry = countryList.find((element) => {
                return element.id === customerProfile.country_id;
            });
            if(selectedCountry && selectedCountry.region){
                setRegion(selectedCountry.region)
                setIsCountrySelected(true);
            }
            
            
        }
    },[customerProfile])
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        
            setFormData({
                ...formData,
                [name]: value
            });  
        

        if(name === 'country_id'){
            const selectedCountry = countryList.find((element) => {
                return element.id === value;
            });
            if(value != ''){
                setRegion(selectedCountry.region);
            }
        }
        
        if(name == 'postcode'){
            if ( value.length < 3  || value.length > 7){
                setErrors({
                    ...errors,
                    postcode: 'Zip Code should be atleast 3 and maximum 7 digit!'
                });
            } else {
                setErrors({
                    ...errors,
                    postcode: ''
                });
            } 
        }
    }

    const findFormErrors = () => {
        const {  card_number, expire_date, ccv, payment_method, terms_condition, card_type, firstname, lastname, street, country_id, region_id, postcode, city } = formData
        const newErrors = {}

            // if ( !cardholder_name || cardholder_name === '' ) newErrors.cardholder_name = 'Card Holder Name cannot be blank!'
            if ( !card_number || card_number === '' ) newErrors.card_number = 'Card Number cannot be blank!'
            if ( !expire_date || expire_date === '' ) newErrors.expire_date = 'Expire Date cannot be blank!'
            if ( !ccv || ccv === '' ) newErrors.ccv = 'CCV cannot be blank!'
            if(!card_type || card_type === '') newErrors.card_type = 'Select card type'
            if ( !firstname || firstname === '' ) newErrors.firstname = 'First Name cannot be blank!'
            if ( !lastname || lastname === '' ) newErrors.lastname = 'Last Name cannot be blank!'
            if ( !country_id || country_id === '' ) newErrors.country_id = 'Country cannot be blank!'
            if ( !region_id || region_id === '' ) newErrors.region_id = 'State cannot be blank!'
            if ( !city || city === '' ) newErrors.city = 'City cannot be blank!'
            if ( !postcode || postcode === '' ) newErrors.postcode = 'Zip Code cannot be blank!'
            if ( postcode && postcode.length < 3  || postcode.length > 7){
                newErrors.postcode = 'Zip Code should be atleast 3 and maximum 7 digit!'
            } 
            if ( !street || street === '' ) newErrors.street= 'Address cannot be blank!'
        
        
        
        return newErrors
    }
    const handleSubmit = (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setForrmSubmited(true);
        // console.log('formData', formData)
        console.log('newErrors', newErrors)
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors);
            setForrmSubmited(false);
        } else {
            updateCardForCurrentSubscription(formData);
        }
    }

    const updateCardForCurrentSubscription = async (formData) => { 
        displayLoading();
            await updateCard({formData: formData}).then(res => {
                if(res && res.payload && res.payload.data && res.payload.data.status){
                    setForrmSubmited(false);
                    // console.log(res.payload.data)
                    Swal.fire({
                        icon: 'success',
                        title: "Card updated successfully!", 
                        text: res.payload.data.message,
                        type: "success", 
                        closeOnConfirm: true
                    }, function(){
                        
                    });
                    // /window.location.href = '/subscription/success/complete';
                } else {
                    setForrmSubmited(false);
                    Swal.fire({
                        icon: 'error',
                        title: "Transcation Failed!", 
                        text: res.payload.data.message,
                        type: "error", 
                        closeOnConfirm: true
                    }, function(){
                        
                    });
                    
                }
            });
            hideLoading();

            
    }




    const getProfile =  async() => {
        
        await getUserProfile({token: user}).then(res => {
            // window.location.href= "/";
        })
    }

    const logout = () => {
        window.location.href='/logout';
    }

  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Settings</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                {
                    isStaff ? <div className="alert alert-danger">You have landed on wrong page. Please contact to <a href="mailto: info@clubkillers.com" className="text-3">Support</a> for more details</div>
                :
                    <div className="">
                        <div className="edit-profile">
                            <div class="title-heading"><h3>Update Card</h3></div>
                        </div>
                        <Form  validated={validated} onSubmit={handleSubmit}>
                            <div className="form-content">
                                <div className="two-column">
                                    {
                                        visaTypeOptions && visaTypeOptions.length > 0 ?
                                            <Form.Group className="form-group" controlid="formCardType">
                                                <Form.Label>Card Type</Form.Label>
                                                <Form.Control  as="select" type="select" name="card_type" onChange={handleChange} isInvalid={ !!errors.card_type }>
                                                    <option value="">Select Card Type</option> 
                                                    {
                                                        visaTypeOptions.map((type, index)=>{
                                                        return <option value={type.value} key={`cardtype-${index}`}>{type.label}</option>
                                                        })
                                                    }
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    { errors.card_type }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        : ''
                                    }
                                
                                    <Form.Group className="form-group" controlid="formCardNumber">
                                        <Form.Label>Card Number</Form.Label>
                                        {/* <Form.Control type="tel" inputmode="numeric" pattern="\d*" autocomplete="cc-number" maxlength="19" placeholder="xxxx xxxx xxxx xxxx" name="card_number" onChange={handleChange} isInvalid={ !!errors.card_number } />  */}
                                        {
                                            formData.card_type == 'Amex' ? 
                                            <PatternFormat customInput={Form.Control} name="card_number" format="#### #### #### ###" mask="X" placeholder="XXXX XXXX XXXX XXX" onChange={handleChange} isInvalid={ !!errors.card_number } />
                                            : 
                                                <PatternFormat customInput={Form.Control} name="card_number" format="#### #### #### ####" mask="X" placeholder="XXXX XXXX XXXX XXXX" onChange={handleChange} isInvalid={ !!errors.card_number } />
                                        }
                                        <Form.Control.Feedback type="invalid">
                                            { errors.card_number }
                                        </Form.Control.Feedback>
                                        
                                    </Form.Group>
                                </div>
                                <div className="two-column">
                                    <Form.Group className="form-group" controlid="formCardNumber">
                                        <Form.Label>Expiration</Form.Label>
                                        {/* <Form.Control type="text" placeholder="MM/YY" name="expire_date" onChange={handleChange} isInvalid={ !!errors.expire_date } /> */}
                                        <PatternFormat customInput={Form.Control} name="expire_date" format="##/##"  placeholder="MM/YY" onChange={handleChange} isInvalid={ !!errors.expire_date } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.expire_date }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                
                                    <Form.Group className="form-group" controlid="formCardNumber">
                                        <Form.Label>CCV</Form.Label>
                                        <Form.Control className="text-3 form-control" type="text" placeholder="" name="ccv" onChange={handleChange} isInvalid={ !!errors.ccv } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.ccv }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="two-column">
                                    <Form.Group className="form-group" controlid="formBasicEmail" >
                                        <Form.Label>First Name<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="First Name" name="firstname" defaultValue={customerProfile.firstname} onChange={handleChange} isInvalid={ !!errors.firstname } onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.firstname }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlid="formBasicEmail">
                                        <Form.Label>Last Name<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" name="lastname" defaultValue={customerProfile.lastname} onChange={handleChange} isInvalid={ !!errors.lastname } onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.lastname }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="form-group" controlid="formBasicEmail">
                                        <Form.Label>Address<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="street" defaultValue={customerProfile.street} onChange={handleChange} isInvalid={ !!errors.street } onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.street }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                    <div className="two-column">
                                        <Form.Group className="form-group" controlid="formBasicEmail">
                                            <Form.Label>City<span className="required">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="City" name="city" defaultValue={customerProfile.city}   onChange={handleChange} isInvalid={ !!errors.city } onBlur={handleChange} />
                                            <Form.Control.Feedback type="invalid">
                                                { errors.city }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="form-group" controlid="formBasicEmail">
                                            <Form.Label>Zip Code<span className="">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="Zip Code" name="postcode" defaultValue={customerProfile.postcode} onChange={handleChange} onBlur={handleChange} />
                                            <Form.Control.Feedback type="invalid">
                                                { errors.postcode }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div>
                                    {
                                        isCountrySelected && customerProfile && customerProfile.country_id  ? 
                                            <Form.Group className="form-group" controlid="formBasicEmail">
                                                <Form.Label>Country<span className="required">*</span></Form.Label>
                                                <Form.Control  as="select" type="select" name="country_id" onChange={handleChange} defaultValue={parseInt(customerProfile.country_id)} onBlur={handleChange}>
                                                    <option value="">Select country</option> 
                                                    {
                                                        countryList && countryList.length > 0 ?
                                                        countryList.map((country, index)=>{
                                                            return <option value={country.id} key={`country-${country.id}`} selected={customerProfile.country_id == country.id ? true :  false}>{country.name}</option>
                                                        })
                                                        : ''
                                                    }
                                                </Form.Control>
                                                {errors.country_id != '' ? <Form.Control.Feedback type="invalid">
                                                    { errors.country_id }
                                                </Form.Control.Feedback> : '' }
                                            </Form.Group> :
                                            <Form.Group className="form-group" controlid="formBasicEmail">
                                                <Form.Label>Country<span className="required">*</span> </Form.Label>
                                                <Form.Control as="select" type="select" name="country_id" onChange={handleChange} defaultValue={customerProfile.country_id} onBlur={handleChange}>
                                                    <option value="">Select Country </option> 
                                                    {
                                                        countryList && countryList.length > 0 ?
                                                        countryList.map((country, index)=>{
                                                            return <option value={country.id} key={`country-${country.id}`}>{country.name}</option>
                                                        })
                                                        : ''
                                                    }
                                                </Form.Control>
                                                { errors.country_id != '' ? <Form.Control.Feedback type="invalid">
                                                    { errors.country_id }
                                                </Form.Control.Feedback> : '' }
                                            </Form.Group>
                                    }
                                    </div> 
                                    <div>  
                                    {
                                        region && region.length > 0 ?
                                            <Form.Group className="form-group" controlid="formBasicEmail">
                                                <Form.Label>State<span className="required">*</span></Form.Label>
                                                <Form.Control as="select" type="select" name="region_id" onChange={handleChange} defaultValue={customerProfile.region_id} onBlur={handleChange}>
                                                    <option value="">Select Region</option> 
                                                    {
                                                        region.map((reg, index)=>{
                                                        return <option value={reg.id} key={`region-${reg.id}`}>{reg.name}</option>
                                                        })
                                                    }
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    { errors.region_id }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        : ''
                                    }
                                    </div>
                                {
                                formSubmited ?  <button disabled="disabled" className="primary-btn"><Spinner animation="border" size="sm" variant="dark" /></button> : <button className="primary-btn">Update Card</button> 
                                }
							</div>
                        </Form>
                    </div>
                }
                </div>
            </div>
		</div>
		
        
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        agreement: state.subscription.agreement,
        plans: state.subscription.plans,
        customerProfile: state.customer.customerProfile,
        countryList: state.common.countryList,
        user: state.authentication.user,
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        getCountryList: () => dispatch(commonActions.getCountryList()),
        getUserProfile: (token) => dispatch(customerAction.getCustomerProfile(token)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        updateCard: (post) => dispatch(subscriptionAction.updateCard(post)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePayPalPaymentCard);