import * as types from './type';

const initialState = {
  agreement: '',
  plans: '',
  customerPlan: '',
  confirmPayment: '',
  subscriptionDetail: '',
  updateCard: '',
  cancelDiscountApplyResponse: ''
};



export default function subscriptionReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.CUSTOMER_AGGREEMENT: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          agreement: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.APPLY_CANCEL_DISCOUNT: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          cancelDiscountApplyResponse: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.UPDATE_PAYPAL_CARD: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          updateCard: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.MEMBERSHIP_PLANS: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          plans: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.CREDIT_CARD_PAYMENT: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          agreement: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.PAYPAL_RECURRING_PAYMENT: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          agreement: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.GET_CUSTOMER_PLAN: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerPlan: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.CONFIRM_PAYPAL_PAYMENT: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          confirmPayment: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.CANCEL_SUBSCRIPTION: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          agreement: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.CANCEL_SUBSCRIPTION_FEEDBACK: {
      if (action.payload && action.payload.data) {
        return {
          ...state
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.GET_SUBSCRIPTION_DETAILS: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          subscriptionDetail: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.PROCESS_MOBILE_PAYMENTS: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state
        }
      } else {
        return {
          ...state
        }
      }
    }
    default: {
      return {
        ...state
      };
    }
    
  }

}