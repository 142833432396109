import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Mtk = {
    getPreviousChamionship,
    getLatestUploadsThisWeek,
    getTop10ThisWeek,
    getTop10ThisMonth,
    getTop20Tracks,
    getTop20Ablums
}

function getPreviousChamionship(){
    const url = 'mtk-song/get-last-month-championship.json?store_id=1&lang_code=en';
    const data = {token: USER_TOKEN}
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getLatestUploadsThisWeek(){
    const url = 'mtk-song/get-latest-upload-this-week.json?store_id=1&lang_code=en';
    const data = {token: USER_TOKEN}
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getTop10ThisWeek(){
    const url = 'mtk-song/top10-this-week.json?store_id=1&lang_code=en';
    const data = {token: USER_TOKEN}
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getTop10ThisMonth(){
    const url = 'mtk-song/top10-this-month.json?store_id=1&lang_code=en';
    const data = {token: USER_TOKEN}
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getTop20Tracks(data){
    const url = 'mtk-song/mtk-top20-tracks.json?store_id=1&lang_code=en';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getTop20Ablums(data){
    
    const url = 'mtk-song/mtk-monthly-top20-albums.json?store_id=1&lang_code=en';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}