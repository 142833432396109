import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import RecentTickets from '../../components/helpdesk/recentTickets';
import TicketSummary from '../../components/helpdesk/ticketSummary';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Pagination from "react-js-pagination";
import Spinner from 'react-bootstrap/Spinner';
import {  useParams, useLocation } from "react-router-dom";
import config from '../../config/config';
import {connect} from 'react-redux';
import * as helpdeskAction from '../../store/helpdesk/actions';

const ViewTicket = ({ getTicket, ticket, submitReply, uploadAttachments, uploadedAttachments}) => {
    let match = useParams("/helpdesk/ticket/details/:ticketNumber");
    const location = useLocation();
    
    const [ page, setPage ] = useState();
    const [formData, setFormData] = useState({
        details: '',
        ip: '',
        ticket_number: match.ticketNumber,
        attachments: ''
    });
    const [ attachments, setAttachments ] = useState();
    const [fileMessage, setFileMessage] = useState();
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [ isSubmitted, setIsSubmitted ] = useState(false);
    const [ processing, setProcessing ] = useState(false);

    useEffect(() => { 
        loadPageData();
    },[location]);

    const loadPageData = async() => {
        await getTicket({ticket_number: match.ticketNumber, page: 1, pageLimit: 10}, () => {
        });
        window.scrollTo({top: 0});
    }

    const handlePageChange = (pageNumber) => {
        getTicket({ticket_number: match.ticketNumber, page: pageNumber, pageLimit: 10}, () => {
            // this.setState({genres: this.props.song.latestSong});
            window.scrollTo({top: 0});
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setFormData({
        //     ...formData,
        //     [name]: value
        // });   
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        findFormErrors();
    }

    const findFormErrors = () => {
        const { details } = formData
        const newErrors = {}
        
        if ( !details || details === '' ) newErrors.details = 'Message cannot be blank!'
        if(isSubmitted){
            setErrors(newErrors)
        }
        return newErrors
    }

    const handleSubmit = (event) => {
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setIsSubmitted(true);
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            sendReply(formData);
        }
    }
    // console.log('ticket', ticket)
    const sendReply = async(data) => {
        setProcessing(true);
        if(attachments && attachments.length > 0){
            const formDataCustom = new FormData();
            for(let i=0; i<attachments.length ;i++){
                formDataCustom.append('UploadAttachmentsForm[attachments]['+i+']', attachments[i]);
            }
            
            // formDataCustom.append('details', formData.details);
            // formDataCustom.append('ticket_number', formData.ticket_number); 
            await uploadAttachments(formDataCustom).then((res)=> {
                if(res && res.payload && res.payload.data &&  res.payload.data.UploadAttachmentsForm && res.payload.data.UploadAttachmentsForm.name && res.payload.data.UploadAttachmentsForm.name.attachments){
                    postReply(res.payload.data.UploadAttachmentsForm.name.attachments)
                }
                
            })
            // if(uploadedAttachments.UploadAttachmentsForm.name);
            // formData.attachments = uploadedAttachments.UploadAttachmentsForm.name.attachments
            // await submitReply(formData, ()=>{
                
            // });
        } else {
            const postData = formData;
            setFormData({
                details: '',
                ip: '',
                ticket_number: match.ticketNumber,
                attachments: ''
            })
            await submitReply(postData, ()=>{
                
            });
        }
        setProcessing(false);
        
    }

    const postReply = (attachments) => {
        formData.attachments = attachments;
        const postData = formData;
        setFormData({
            details: '',
            ip: '',
            ticket_number: match.ticketNumber,
            attachments: ''
        })
        submitReply(postData, ()=>{
            
        });
    }

    const changeFile = (event) => {
        let files = event.target.files,
            value = event.target.value,
            message;
        if( files && files.length > 1 ){
            message = `${files.length} files selected`;
        } else{
            message = value.split( '\\' ).pop();
        }   
        if(message){
            setFileMessage(message);   
        }  
        setAttachments(event.target.files);   
        // console.log(event.target.files)              
    }
    

  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>HELP DESK</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                    <div className="ticket-sec">
                        <div className="title-heading">
                            <h3>Tickets </h3>
                        </div>
                        <div className="inner-ticket-block">
                            <div className="left">
                                <TicketSummary />
                                <div className="title-heading">
                                    <h3>Ticket #{match.ticketNumber}</h3>
                                </div>
                                <div className="form-content">
                                <Form  validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group className="form-group" controlid="formDetails" style={{ minWidth: '70%'}}>
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" placeholder="Please enter your message. A member of our support staff will responde as soon as possible" style={{ height: '100px', minWidth: '100%'}} name="details" value={formData.details}  onChange={handleChange} isInvalid={ !!errors.details } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.details }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlid="formFileLg" className="form-group">
                                        <Form.Label>Attachments</Form.Label>
                                        <Form.Control type="file" size="lg" onChange={(e) => {
                                                                            changeFile(e)
                                                                        }} style={{borderRadius: '0'}} />
                                    </Form.Group>
                                    <div>
                                        {
                                            processing ? 
                                            <button className="primary-btn" disabled="true"><Spinner animation="border" variant="dark" size="sm" /></button> 
                                            : <Button type="submit" variant="light" className="primary-btn">Submit your reply</Button>
                                        }
                                        
                                    </div>
                                </Form>
                                </div>
                                <div className="chat-boat">
                                {
                                    ticket && ticket.replies && ticket.replies.length > 0 ?
                                        ticket.replies.map((reply) => {
                                            return <div className={reply.admin_id === 0 ? 'reply-msg reply-customer' : 'reply-msg reply-admin'} key={`reply${reply.id}`} >
                                                        <div className="reply-content">
                                                            <div className={reply.admin_id === 0 ? "" : ""} dangerouslySetInnerHTML={{__html: reply.details}} />
                                                            {
                                                                reply.attachments && reply.attachments.length > 0 ?
                                                                    <div className="reply-attachments mt-5">
                                                                        <span className="icon-attachment mr-3"></span>
                                                                        {
                                                                            reply.attachments.map((attachment, a) => {
                                                                                return <span key={'attachment-'+a} className="mr-3">
                                                                                    <a className="primary-color" href={`${config.downloadSelfUrl}${attachment.file_path}`} target="_blank">{attachment.filename}</a>
                                                                                </span>
                                                                            })
                                                                        }
                                                                    </div>
                                                                : ''
                                                            }
                                                        </div>
                                                        <div className="reply-date">{reply.created_at}</div>
                                                </div>
                                        })
                                    : ''
                                }
                                </div>
                            </div>
                            <RecentTickets />
                        </div>
                        <div className="pagingnation-sec">
                        {
                            ticket && ticket.pagination ? 
                                <Pagination
                                    activePage={ticket.pagination.page}
                                    itemsCountPerPage={ticket.pagination.perPageLimit}
                                    totalItemsCount={ticket.pagination.totalCount}
                                    // pageRangeDisplayed={pageRangeDisplayed}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                            /> : ''
                        }
                        </div>
                    </div>
                </div>
            </div>
		</div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        ticket: state.helpdesk.ticketDetail,
        uploadedAttachments: state.helpdesk.attachments
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTicket: (post) => dispatch(helpdeskAction.getTicket(post)),
        submitReply: (post) => dispatch(helpdeskAction.submitReply(post)),
        uploadAttachments: (post) => dispatch(helpdeskAction.uploadAttachments(post))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTicket);