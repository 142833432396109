import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";


import MonthlyTopSongs from '../../components/monthlyTopSongs/monthlyTopSongs';
import RightSide from "../../components/rightSide/rightSide";
import SongTrack from '../../components/songTrack/songTrack';
import Pagination from "react-js-pagination";
import SongTrackLoader from '../../components/loader/songTrackLoader';
import MusicDashboard from '../../layouts/musicDashboard';

import { connect } from 'react-redux';

import * as songActions from '../../store/song/actions';
import * as genreActions from '../../store/genres/actions';


const  Charts = ({ loggingIn, homeGenresList, getHomeGenres, chartData, getChartSongs, getTop20Tracks, top20Albums}) => {

  
    const navigate = useNavigate();
    const scrollRef = useRef(null);
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [ currentGenreId, setCurrentGenreId ] = useState('all');
    const [ chartType, setChartType ] = useState('daily');
    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ processing, setProcessing ] = useState(false);

    useEffect(() => { 
		if(loggingIn !== undefined && loggingIn === false){
			navigate('/sign-in');
		} else {
			loadData()
		}
	},[])

  const loadData = async() => {
    setProcessing(true)
    if(!chartData){
      if(currentGenreId !== 'all'){
        await getChartSongs({chart_type: chartType, page: 1, genre_id: currentGenreId})
      } else {
        await getChartSongs({chart_type: chartType, page: 1, order: order, orderDir: orderDir})
      }
      setProcessing(false);
    }
    if(!homeGenresList){
      await getHomeGenres();
    }
    window.scrollTo({top: 0});
    // await getTop20Tracks({page: 1, limit: 15});
  }

  const genresSong = async (genre_id) => {
    setCurrentGenreId(genre_id);
    setProcessing(true);
    if(genre_id === 'all'){
      await getChartSongs({chart_type: chartType, page: 1}).then((res) => {
      })
    } else {
      await getChartSongs({chart_type: chartType, page: 1, genre_id: genre_id}).then((res) => {
      })
    }
    setProcessing(false);
    window.scrollTo({top: 0});
  }

  const getChartSongByChartType = async (type) => {
    setProcessing(true)
    setChartType(type);
    if(currentGenreId === 'all'){
      await getChartSongs({chart_type: type, page: 1}).then((res) => {
      })
    } else {
      await getChartSongs({chart_type: type, page: 1, genre_id: currentGenreId}).then((res) => {
      })
    }
    setProcessing(false);
    window.scrollTo({top: 0});
  }

  const handlePageChange = async(pageNumber) => {
    setProcessing(true);
    setPage(pageNumber);
    if(currentGenreId === 'all'){
      await getChartSongs({chart_type: chartType, page: pageNumber}).then((res) => {
      })
    } else {
      await getChartSongs({chart_type: chartType, page: pageNumber, genre_id: currentGenreId}).then((res) => {
      })
    }
    setProcessing(false);
    scrollRef.current?.scrollIntoView({behavior: 'smooth'});
  } 

	return (
			<MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />} middleSectionCssClass={"library-section"}>
                <div className="content-sec">
                    <MonthlyTopSongs />
                    <div className="play-list-sec" ref={scrollRef}>
                        <div className="top-heading">
                            <h2>Charts</h2>
                        </div>
                        <div className="top-tab">
                            <ul className="tab-sec">
                                <li><span className={chartType === 'daily' ? "active cp" : 'cp'} onClick={() => getChartSongByChartType('daily')}>Daily</span></li>
                                <li className="divide"><span className={chartType === 'weekly' ? "active cp" : 'cp'} onClick={() => getChartSongByChartType('weekly')}>Weekly</span></li>
                                <li><span className={chartType === 'monthly' ? "active cp" : 'cp'} onClick={() => getChartSongByChartType('monthly')}>Monthly</span></li>
                            </ul>
                        </div>
                        
                        <div className="inner-block">
                            <div className="top-list">
                                
                                    {
                                        homeGenresList && homeGenresList.length > 0 ? 
                                        <ul>
                                            <li className={currentGenreId === 'all' ? 'active cp' : 'cp' } onClick={() => genresSong('all')}><span>All</span></li>
                                            {
                                                homeGenresList.map((genre, index)=>{
                                                    return <li key={`homeGenresSong${genre.genre_id}`} className={currentGenreId === genre.genre_id ? 'active cp' : 'cp'}><span className="text-3" onClick={() => genresSong(genre.genre_id)} dangerouslySetInnerHTML={{__html: genre.name}}></span></li>
                                                })
                                            }
                                        </ul>
                                        : ""
                                    }
                            </div>
                            { processing ? <SongTrackLoader itemCount={20} /> : chartData && chartData.songs && chartData.songs.length > 0 ? <SongTrack songs={chartData.songs} /> : ''}
                        </div>
                    </div>
                    <div className="pagingnation-sec">
                    {
                        chartData && chartData.pagination ? 
                        <Pagination
                            activePage={chartData.pagination.page}
                            itemsCountPerPage={chartData.pagination.perPageLimit}
                            totalItemsCount={chartData.pagination.totalCount}
                            // pageRangeDisplayed={pageRangeDisplayed}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        /> : ''
                    }
                    </div>
                </div>
        </MusicDashboard>
    );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        homeGenresList: state.genre.homeGenreList,
        chartData: state.song.chartData,
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
        getHomeGenres: () => dispatch(genreActions.getHomeGenres()),
        getChartSongs: (post) => dispatch(songActions.getChartSongs(post)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(Charts);
