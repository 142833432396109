import React, { useState, useEffect } from "react";


import RightSide from "../../components/rightSide/rightSide";

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import MusicDashboard from '../../layouts/musicDashboard';

import GenresLoader from '../../components/loader/genresLoader';
import { Link, useNavigate } from "react-router-dom";

import { connect } from 'react-redux';
import * as genreActions from '../../store/genres/actions';



const  DownloadReports = ({  getGenresDownloadReport, genresDownloadReport, displayLoading, hideLoading }) => {

    const navigate = useNavigate();
   
    
    useEffect(() => { 
		loadData();
	},[])

	const loadData = async() => {
        displayLoading();
        await getGenresDownloadReport();
        hideLoading();
        window.scrollTo({top: 0});
    }
    
	return (
			<MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
                <div className="content-sec">
                    <div className="genres-sec">
                        <div className="top-heading">
                            <div className="back-arrow-head">
                                <span className="cp" onClick={() => {
                                    navigate(-1)
                                }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </span>
                                <h2>Download Reports</h2>
                            </div>
                        </div>
                        {
                            genresDownloadReport  && genresDownloadReport.genres && genresDownloadReport.genres.length > 0 ? 
                                genresDownloadReport.genres.map((genre, index) => {
                                    return <div className="genres-block" key={`genresHome${genre.genre_id}`}>
                                        <div className="img-block">
                                            <img src={genre.image} title={genre.name} />
                                            <h5 dangerouslySetInnerHTML={{__html: genre.name}}></h5>
                                        </div>
                                        {
                                            genre.subgenres && genre.subgenres.length > 0 ?
                                                <div className="genres-right-block" >
                                                    <div className="tags">
                                                    {
                                                        genre.subgenres.map((subGenre, subGenreIndex) => {
                                                            return <Link to={`/mp3/download-report/genre/${subGenre.genre_id}`} key={`genreSubGEnreHome${subGenre.genre_id}`}><span  dangerouslySetInnerHTML={{__html: subGenre.name}}></span></Link>
                                                        })
                                                    }
                                                        
                                                    </div>
                                                </div>
                                                
                                            : ''
                                        }
                                    </div>
                                })
                            : <GenresLoader itemCount={16} />
                        }
                    </div>
                </div>
        </MusicDashboard>
    );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        genresDownloadReport: state.genre.genresDownloadReport,
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		getGenresDownloadReport: () => dispatch(genreActions.getGenresDownloadReport()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(DownloadReports);
