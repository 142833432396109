import React, {useState, useEffect} from "react";

import singlePageLayout from "../../../layouts/singlePageLayout/singlePageLayout";

import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Pagination from "react-js-pagination";
// import logo from './../../assets/images/Logo.svg';
import logo from './../../../assets/images/cklogo.png';
import * as actions from '../../../store/career/actions';

import '../mixes.scss';

const Career = ({ getCareerLists, careerLists, displayLoading, hideLoading }) => {

	const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [ currentGenreId, setCurrentGenreId ] = useState();
    const [ processing, setProcessing ] = useState(false);
   
	useEffect(() => {
        displayLoading();
        setProcessing(true);
        getCareerLists({page: page, order: order, orderDir: orderDir});
		document.body.classList.add('hideplayer');
        hideLoading();
            setProcessing(false);
	},[]);

	
    
	
  return (
    <singlePageLayout>
    {/* // <MusicDashboard middleSectionCssclassName={'account-info-sec'}> */}
		<SimpleBar style={{ height: '100vh' }}>
            <div className="outer-singlelayout-pages">
                <div className="container-sec">
                    <div className="top">
                        <Link to={`/`} className="logo"><img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in" style={{maxWidth: '100px' }} /></Link>
                        <Link to={`/`} className="close">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
                            </svg>
                        </Link>
                    </div>
                    <div className="osp-content-area">
                        <h1>Current Opening</h1>
                        <br />
                        {
                            careerLists && careerLists.length > 0 ?
                                careerLists.map((cList, index)=>{
                                    return <div className="c-list-row d-f-row" key={`clist-${index}`}>
                                        <div className="r-title">{cList.title}</div>
                                        <div className="r-intro text-5">{cList.intro}</div>
                                        <div className="r-action">
                                            <Link to={`/career/apply/${cList.career_id}`} class="primary-btn" style={{height: '30px', padding: '0 8px', minWidth: '80px'}}>Apply</Link>
                                        </div>
                                    </div>
                                })
                            : ''
                        }
                    </div>
                </div>
            </div>
		</SimpleBar>
    {/* </MusicDashboard> */}
    </singlePageLayout>
  );
}

function mapStateToProps(state) {
  return {
		loggingIn: state.authentication.loggedIn,
        careerLists: state.career.careerLists
  };
}

function mapDispatchToProps(dispatch) {
  return {
	getCareerLists: (data) => dispatch(actions.getCareerLists(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Career);