import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link }  from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import ImageSliderLoader from '../../components/loader/imageSliderLoader';

import {connect} from 'react-redux';
import * as actions from '../../store/curated/actions';


const  CuratedSpotlightCarousel = ({ curatedItems, getCuratedItems, title, hideSeeAll, showItemCount }) => {

	
	useEffect(() => { 
		getCuratedItems();
	},[])

	const settings = {
		// className: "slider variable-width",
		// dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		// arrows: true,
		responsive: [
		//   {
		// 	breakpoint: 1700,
		// 	settings: {
		// 	  // slidesToShow: 4,
		// 	  // slidesToScroll: 4,
		// 	}
		//   },
		//   {
		// 	breakpoint: 1600,
		// 	settings: {
		// 	  // slidesToShow: showItemCount ? showItemCount : 4,
		// 	  // slidesToScroll: showItemCount ? showItemCount : 4,
		// 	}
		//   },
		//   {
		// 	breakpoint: 767,
		// 	settings: {
		// 	  slidesToShow: 1,
		// 	  slidesToScroll: 1,
		// 	}
		//   },
		]
	};

	// var settings = {
	// 	dots: true,
	// 	infinite: true,
	// 	speed: 500,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1
	// };
    
    return (
      <div className="slider-sec">
			<div className="top-heading">
				<h2>{title}</h2>
				{hideSeeAll && hideSeeAll == true ? '' : <Link to={`/mp3/spotlight/home`} className="text-2">View all</Link> }
			</div>
			{
				curatedItems && curatedItems.length > 0 ?
					<Slider {...settings}>
						{
							curatedItems.map((item, index) => {
								if(item.total > 0){
									return <div key={`curatedSpotlight${item.curated_list_id}${index}`} className="item">
										{
											index == 8 ?
												<div className="box viewall">
													<Link to={`/mp3/spotlight/home`}>VIEW ALL</Link>
												</div>
											:
											<div className="box">
												<Link to={`/mp3/spotlight/detail/${item.curated_list_id}`}><img src={item.image} alt={item.title} title={item.title} /></Link>
												<Link to={`/mp3/spotlight/detail/${item.curated_list_id}`}>	<h5>{item.created_by}</h5> </Link>
											</div>
										}
									</div>
								} else {
									return null;
								}
							})
						}
					</Slider>
				: 
				// <div style={{textAlign: 'center'}}><Spinner animation="border" variant="danger" size="lg" /></div>
				<ImageSliderLoader itemCount={8} />
			}
			
		</div>
				
				
    );
  
}


function mapStateToProps(state) {
	return {
		curatedItems: state.curated.homeCuratedList
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		getCuratedItems: () => dispatch(actions.getHomeCuratedLists()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CuratedSpotlightCarousel);
